declare var window: any;
var URL_BASE = window.config.apiURL || "https://sissai-api.magiadigital.com/api/v1/"

export const baseURLSet = {
	default: URL_BASE
};

//----------Admin----------\\

/* Cuenta */
export const CUENTA_LOGIN = "admin/cuenta/login";
export const CUENTA_LOGIN_HASH = "admin/cuenta/login-hash";
export const CUENTA_SOLICITA_RECUPERA_PASSWORD = "admin/cuenta/solicita-recupera-password";
export const CUENTA_VALIDA_HASH_RECUPERA_PASSWORD = "admin/cuenta/valida-hash-recupera-password/";
export const CUENTA_VALIDA_HASH_ACTIVA_CUENTA = "admin/cuenta/valida-hash-activa-cuenta/";
export const CUENTA_ACTIVA_CUENTA = "admin/cuenta/activa-cuenta";
export const CUENTA_RECUPERA_PASSWORD = "admin/cuenta/recupera-password";
export const CUENTA_CAMBIA_PASSWORD = "admin/cuenta/cambia-password";
export const CUENTA_TABLERO_CONTROL = "admin/cuenta/tablero-control";
export const CUENTA_MENU_LATERAL = "admin/cuenta/menu-lateral";

export const CUENTA_DESCARGA_QR_ZIP = "client/cuenta/descarga-qr-zip";
export const CUENTA_DESCARGA_QR = "client/cuenta/descarga-qr/";

/* Cuenta - client */
export const CUENTA_AGREGA_USUARIO = "client/cuenta/agrega-usuario";
export const CUENTA_ACTUALIZA_USUARIO = "client/cuenta/actualiza-usuario";
export const CUENTA_ELIMINA_USUARIO = "client/cuenta/elimina-usuario/";
export const CUENTA_LISTA_USUARIO = "client/cuenta/lista-usuario";
export const CUENTA_SELECCIONA_USUARIO = "client/cuenta/selecciona-usuario/";

export const CUENTA_AGREGA_USUARIO_STAKEHOLDER = "client/cuenta/agrega-usuario-stakeholder/";
export const CUENTA_ACTUALIZA_USUARIO_STAKEHOLDER = "client/cuenta/actualiza-usuario-stakeholder/";
export const CUENTA_ELIMINA_USUARIO_STAKEHOLDER = "client/cuenta/elimina-usuario-stakeholder/";
export const CUENTA_LISTA_USUARIO_STAKEHOLDER = "client/cuenta/lista-usuario-stakeholder";
export const CUENTA_SELECCIONA_USUARIO_STAKEHOLDER = "client/cuenta/selecciona-usuario-stakeholder/";

/* Proceso */

export const PROCESO_SELECCIONA = "client/proceso/selecciona/";
export const PROCESO_AGREGA = "client/proceso/agrega"; 
export const PROCESO_ACTUALIZAR = "client/proceso/actualiza";
export const PROCESO_LISTA_PROCESO_METADATA = "client/proceso/metadata-etapas";
export const PROCESO_LISTA_PROCESO_ETAPA = "client/proceso/lista-etapa";

export const PROCESO_LISTA_ETAPAS_PARENT = "client/proceso/lista-etapas-parent/";
export const PROCESO_LISTA_REGISTROS_PARENT = "client/proceso/lista-registros-parent";

export const PROCESO_REGISTROS_CONFECCION = "client/proceso/registros-confeccion";
export const PROCESO_GENERA_PRODUCTO_CODE = "client/proceso/genera-producto-code/";
export const PROCESO_LISTA_PRODUCTO_CODE = "client/proceso/lista-producto-code/";
export const PROCESO_LISTA_CODESKU = "client/Proceso/lista-codesku";
export const PROCESO_DESCARGA_QR = "client/proceso/descarga-qr/";
export const PROCESO_DESCARGA_PIN = "client/proceso/descarga-pin/";
export const PROCESO_ACTUALIZAR_TRAZABILIDAD_BLOCKCHAIN = "client/proceso/actualizar-trazabilidad-blockchain";

/* Stakeholders */

export const STAKEHOLDER_AGREGAR_STAKEHOLDER = "client/stakeholder/agrega-stakeholder";
export const STAKEHOLDER_ACTUALIZAR_STAKEHOLDER = "client/stakeholder/actualiza-stakeholder";
export const STAKEHOLDER_ELIMINAR_STAKEHOLDER = "client/stakeholder/elimina-stakeholder/";
export const STAKEHOLDER_LISTA_STAKEHOLDER = "client/stakeholder/lista-stakeholder";
export const STAKEHOLDER_LISTA_STAKEHOLDER2 = "client/stakeholder/lista-stakeholder2";
export const STAKEHOLDER_SELECCIONA_STAKEHOLDER = "client/stakeholder/selecciona-stakeholder/";

export const STAKEHOLDER_AGREGAR_TIPO_STAKEHOLDER = "client/stakeholder/agrega-tipo-stakeholder";
export const STAKEHOLDER_ACTUALIZAR_TIPO_STAKEHOLDER = "client/stakeholder/actualiza-tipo-stakeholder";
export const STAKEHOLDER_ELIMINAR_TIPO_STAKEHOLDER = "client/stakeholder/elimina-tipo-stakeholder/";
export const STAKEHOLDER_LISTA_TIPO_STAKEHOLDER = "client/stakeholder/lista-tipo-stakeholder";
export const STAKEHOLDER_SELECCIONA_TIPO_STAKEHOLDER = "client/stakeholder/selecciona-tipo-stakeholder/";

/* Cadenas Valor */

export const CADENA_VALOR_LISTA_ETAPA = "client/cadena-valor/lista-etapa/";

export const CADENA_VALOR_AGREGA = "client/cadena-valor/agrega";
export const CADENA_VALOR_ACTUALIZA = "client/cadena-valor/actualiza";
export const CADENA_VALOR_ELIMINA = "client/cadena-valor/elimina/";
export const CADENA_VALOR_LISTA = "client/cadena-valor/lista/";

export const CADENA_VALOR_AGREGA_LOTE = "client/cadena-valor/agrega-lote";
export const CADENA_VALOR_ACTUALIZA_LOTE = "client/cadena-valor/actualiza-lote";
export const CADENA_VALOR_ELIMINA_LOTE = "client/cadena-valor/elimina-lote/";
export const CADENA_VALOR_SELECCIONA_LOTE = "client/cadena-valor/selecciona-lote/";

/* Reporte */

export const REPORTE_LISTA_ETAPA = "client/reporte/lista-etapa/";
export const REPORTE_LISTA_PROCESO = "client/reporte/lista-proceso";
export const REPORTE_DETALLE_PROCESO = "client/reporte/detalle-proceso/";
export const REPORTE_DESCARGA_EXCEL_PROCESO = "client/reporte/descarga-excel-proceso";

/* Comercio */

export const COMERCIO_AGREGA = "client/comercio/agrega";
export const COMERCIO_ACTUALIZA = "client/comercio/actualiza";
export const COMERCIO_ELIMINA = "client/comercio/elimina/";
export const COMERCIO_LISTA = "client/comercio/lista";
export const COMERCIO_SELECCIONA = "client/comercio/selecciona/";
export const COMERCIO_LISTA_CADENA_VALOR = "client/comercio/lista-cadena-valor/";
export const COMERCIO_LISTA_LOTE = "client/comercio/lista-lote/";
export const COMERCIO_DESCARGA_EXCEL = "client/comercio/descarga-excel";


/* Internal Page */
export const INTERNAL_PAGE_GET = "internal-page/get";
export const INTERNAL_PAGE_LIST = "internal-page/list";

/* Banners */
export const BANNER_GET = "banner/get";
export const BANNER_LIST = "banner/list";

/* Events */
export const EVENTS_CATEGORIES = "events/categories";
export const EVENTS_UPCOMING = "events/upcoming-events";
export const EVENTS_GET = "events/get";
export const EVENTS_LIST = "events/list";

/* Gallery */
export const GALLERY_LIST = "gallery/list";
export const GALLERY_GET_CONTENT = "gallery/get-content";
export const GALLERY_LIST_CONTENT = "gallery/list-content";

/* News */
export const NEWS_CATEGORIES = "news/categories";
export const NEWS_INTERESTING = "news/interesting-news";
export const NEWS_LIST = "news/list";
export const NEWS_GET = "news/get";

/* Page Zone */
export const PAGE_ZONE_GET = "page-zone/get";
export const PAGE_ZONE_LIST = "page-zone/list";

/* Form */
export const FORM_GET = "form/get";
export const FORM_SEND = "form/send";

/* Searcher */
export const SEARCHER_LIST = "searcher/list";
