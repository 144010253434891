interface IActionType {

    CUENTA_LOGIN_ADD: string,
    CUENTA_LOGIN_CALL: string,
    CUENTA_CONTROL_TABLERO_ADD: string,
    CUENTA_CONTROL_TABLERO_CALL: string,
    CUENTA_CAMBIA_PASSWORD_CALL: string,
    CUENTA_CAMBIA_PASSWORD_ADD: string,
    CUENTA_MENU_LATERAL_ADD: string,
    CUENTA_MENU_LATERAL_CALL: string,

    CUENTA_AGREGA_USUARIO_CALL: string,
    CUENTA_ACTUALIZA_USUARIO_CALL: string,
    CUENTA_ELIMINA_USUARIO_CALL: string,
    CUENTA_LISTA_USUARIO_ADD: string,
    CUENTA_LISTA_USUARIO_CALL: string,
    CUENTA_SELECCIONA_USUARIO_ADD: string,
    CUENTA_SELECCIONA_USUARIO_CALL: string,

    CUENTA_AGREGA_USUARIO_STAKEHOLDER_CALL: string,
    CUENTA_ACTUALIZA_USUARIO_STAKEHOLDER_CALL: string,
    CUENTA_ELIMINA_USUARIO_STAKEHOLDER_CALL: string,
    CUENTA_LISTA_USUARIO_STAKEHOLDER_ADD: string,
    CUENTA_LISTA_USUARIO_STAKEHOLDER_CALL: string,
    CUENTA_SELECCIONA_USUARIO_STAKEHOLDER_ADD: string,
    CUENTA_SELECCIONA_USUARIO_STAKEHOLDER_CALL: string,
    CUENTA_LISTA_STAKEHOLDER_CALL: string,
    CUENTA_LISTA_STAKEHOLDER_ADD: string,
    CUENTA_DESCARGA_QR: string,
    CUENTA_DESCARGA_QR_ZIP: string,

    PROCESO_AGREGA_CALL: string,
    PROCESO_ACTUALIZA_CALL: string,
    PROCESO_SELECCIONA_CALL: string,
    PROCESO_SELECCIONA_ADD: string,
    PROCESO_LISTA_PROCESO_METADATA_ADD: string,
    PROCESO_LISTA_PROCESO_METADATA_CALL: string,
    PROCESO_LISTA_PROCESO_ETAPA_ADD: string,
    PROCESO_LISTA_PROCESO_ETAPA_CALL: string,
    PROCESO_LISTA_ETAPAS_PARENT_ADD: string,
    PROCESO_LISTA_ETAPAS_PARENT_CALL: string,
    PROCESO_LISTA_REGISTROS_PARENT_ADD: string,
    PROCESO_LISTA_REGISTROS_PARENT_CALL: string,
    PROCESO_REGISTROS_CONFECCION_ADD: string,
    PROCESO_REGISTROS_CONFECCION_CALL: string,
    PROCESO_GENERA_PRODUCTO_CODE_ADD: string,
    PROCESO_GENERA_PRODUCTO_CODE_CALL: string,
    PROCESO_LISTA_PRODUCTO_CODE_ADD: string,
    PROCESO_LISTA_PRODUCTO_CODE_CALL: string,
    PROCESO_LISTA_CODESKU_ADD: string,
    PROCESO_LISTA_CODESKU_CALL: string,
    PROCESO_DESCARGA_PIN_ADD: string,
    PROCESO_DESCARGA_PIN_CALL: string,
    PROCESO_DESCARGA_QR_ADD: string,
    PROCESO_DESCARGA_QR_CALL: string,
    PROCESO_ACTUALIZAR_TRAZABILIDAD_BLOCKCHAIN: string,

    STAKEHOLDER_AGREGA_STAKEHOLDER_CALL: string,
    STAKEHOLDER_ACTUALIZA_STAKEHOLDER_CALL: string,
    STAKEHOLDER_ELIMINA_STAKEHOLDER_CALL: string,
    STAKEHOLDER_LISTA_STAKEHOLDER_CALL: string,
    STAKEHOLDER_LISTA_STAKEHOLDER_ADD: string,
    STAKEHOLDER_SELECCIONA_STAKEHOLDER_CALL: string,
    STAKEHOLDER_SELECCIONA_STAKEHOLDER_ADD: string,

    STAKEHOLDER_AGREGA_TIPO_STAKEHOLDER_CALL: string,
    STAKEHOLDER_ACTUALIZA_TIPO_STAKEHOLDER_CALL: string,
    STAKEHOLDER_ELIMINA_TIPO_STAKEHOLDER_CALL: string,
    STAKEHOLDER_PROCESO_TIPO_STAKEHOLDER_ADD: string,
    STAKEHOLDER_LISTA_TIPO_STAKEHOLDER_CALL: string,
    STAKEHOLDER_LISTA_TIPO_STAKEHOLDER_ADD: string,
    STAKEHOLDER_SELECCIONA_TIPO_STAKEHOLDER_CALL: string,
    STAKEHOLDER_SELECCIONA_TIPO_STAKEHOLDER_ADD: string,

    CADENA_VALOR_LISTA_ETAPA_CALL: string,
    CADENA_VALOR_LISTA_ETAPA_ADD: string,

    CADENA_VALOR_AGREGA_CALL: string,
    CADENA_VALOR_ACTUALIZA_CALL: string,
    CADENA_VALOR_ELIMINA_CALL: string,
    CADENA_VALOR_LISTA_CALL: string,
    CADENA_VALOR_LISTA_ADD: string,

    CADENA_VALOR_AGREGA_LOTE_CALL: string,
    CADENA_VALOR_ACTUALIZA_LOTE_CALL: string,
    CADENA_VALOR_ELIMINA_LOTE_CALL: string,
    CADENA_VALOR_SELECCIONA_LOTE_CALL: string,
    CADENA_VALOR_SELECCIONA_LOTE_ADD: string,

    REPORTE_LISTA_ETAPA_CALL: string,
    REPORTE_LISTA_ETAPA_ADD: string,
    REPORTE_LISTA_PROCESO_CALL: string,
    REPORTE_LISTA_PROCESO_ADD: string,
    REPORTE_DESCARGA_EXCEL_PROCESO_CALL: string,
    REPORTE_DESCARGA_EXCEL_PROCESO_ADD: string,
    REPORTE_CADENA_VALOR_LISTA_CALL: string,
    REPORTE_CADENA_VALOR_LISTA_ADD: string,
    REPORTE_PROCESO_METADATA_ETAPAS_CALL: string,
    REPORTE_PROCESO_METADATA_ETAPAS_ADD: string,
    REPORTE_DETALLE_PROCESO_CALL: string,
    REPORTE_DETALLE_PROCESO_ADD: string,
    

    COMERCIO_AGREGA_CALL: string,
    COMERCIO_ACTUALIZA_CALL: string,
    COMERCIO_ELIMINA_CALL: string,
    COMERCIO_SELECCIONA_CALL: string,
    COMERCIO_SELECCIONA_ADD: string,
    COMERCIO_LISTA_CALL: string,
    COMERCIO_LISTA_ADD: string,
    COMERCIO_LISTA_CADENA_VALOR_CALL: string,
    COMERCIO_LISTA_CADENA_VALOR_ADD: string,
    COMERCIO_LISTA_LOTE_CALL: string,
    COMERCIO_LISTA_LOTE_ADD: string,
    COMERCIO_DESCARGA_EXCEL_CALL: string,
    COMERCIO_DESCARGA_EXCEL_ADD: string,

    MENU_GLOBAL_ADD: string,




    INTERNAL_PAGE_CALL_LIST: string,
    INTERNAL_PAGE_ADD_LIST: string,
    INTERNAL_PAGE_CALL_GET: string,
    INTERNAL_PAGE_ADD_GET: string,

    PAGE_ZONE_CALL_LIST: string,
    PAGE_ZONE_ADD_LIST: string,
    PAGE_ZONE_CALL_GET: string,
    PAGE_ZONE_ADD_GET: string,

    BANNER_CALL_LIST: string,
    BANNER_ADD_LIST: string,
    BANNER_CALL_GET: string,
    BANNER_ADD_GET: string,

    NEWS_CALL_CATEGORIES: string,
    NEWS_ADD_CATEGORIES: string,
    NEWS_CALL_INTERESTING: string,
    NEWS_ADD_INTERESTING: string,
    NEWS_CALL_LIST: string,
    NEWS_ADD_LIST: string,
    NEWS_CALL_GET: string,
    NEWS_ADD_GET:string,
	
    GALLERY_CALL_LIST: string,
	GALLERY_ADD_LIST: string,
    GALLERY_CALL_LIST_CONTENT: string,
    GALLERY_ADD_LIST_CONTENT: string,

    EVENTS_CALL_CATEGORIES: string,
    EVENTS_ADD_CATEGORIES: string,
    EVENTS_CALL_UPCOMING: string,
    EVENTS_ADD_UPCOMING: string,
    EVENTS_CALL_LIST: string,
    EVENTS_ADD_LIST: string,
    EVENTS_CALL_GET: string,
    EVENTS_ADD_GET:string,

    FORM_CALL_GET: string,
    FORM_ADD_GET:string,
    FORM_CALL_SEND: string,
    FORM_ADD_SEND: string,
	
	SEARCHER_CALL_LIST: string,
    SEARCHER_ADD_LIST: string,
}

const ActionType: IActionType = {} as IActionType;

ActionType.CUENTA_LOGIN_ADD = "CUENTA_LOGIN_ADD";
ActionType.CUENTA_LOGIN_CALL = "CUENTA_LOGIN_CALL";
ActionType.CUENTA_CONTROL_TABLERO_ADD = "CUENTA_CONTROL_TABLERO_ADD";
ActionType.CUENTA_CONTROL_TABLERO_CALL = "CUENTA_CONTROL_TABLERO_CALL";
ActionType.CUENTA_CAMBIA_PASSWORD_CALL = "CUENTA_CAMBIA_PASSWORD_CALL";
ActionType.CUENTA_CAMBIA_PASSWORD_ADD = "CUENTA_CAMBIA_PASSWORD_ADD";
ActionType.CUENTA_MENU_LATERAL_ADD = "CUENTA_MENU_LATERAL_ADD";
ActionType.CUENTA_MENU_LATERAL_CALL = "CUENTA_MENU_LATERAL_CALL";

ActionType.CUENTA_AGREGA_USUARIO_CALL = "CUENTA_AGREGA_USUARIO_CALL";
ActionType.CUENTA_ACTUALIZA_USUARIO_CALL = "CUENTA_ACTUALIZA_USUARIO_CALL";
ActionType.CUENTA_ELIMINA_USUARIO_CALL = "CUENTA_ELIMINA_USUARIO_CALL";
ActionType.CUENTA_LISTA_USUARIO_ADD = "CUENTA_LISTA_USUARIO_ADD";
ActionType.CUENTA_LISTA_USUARIO_CALL = "CUENTA_LISTA_USUARIO_CALL";
ActionType.CUENTA_SELECCIONA_USUARIO_ADD = "CUENTA_SELECCIONA_USUARIO_ADD";
ActionType.CUENTA_SELECCIONA_USUARIO_CALL = "CUENTA_SELECCIONA_USUARIO_CALL";

ActionType.CUENTA_AGREGA_USUARIO_STAKEHOLDER_CALL = "CUENTA_AGREGA_USUARIO_STAKEHOLDER_CALL";
ActionType.CUENTA_ACTUALIZA_USUARIO_STAKEHOLDER_CALL = "CUENTA_ACTUALIZA_USUARIO_STAKEHOLDER_CALL";
ActionType.CUENTA_ELIMINA_USUARIO_STAKEHOLDER_CALL = "CUENTA_ELIMINA_USUARIO_STAKEHOLDER_CALL";
ActionType.CUENTA_LISTA_USUARIO_STAKEHOLDER_ADD = "CUENTA_LISTA_USUARIO_STAKEHOLDER_ADD";
ActionType.CUENTA_LISTA_USUARIO_STAKEHOLDER_CALL = "CUENTA_LISTA_USUARIO_STAKEHOLDER_CALL";
ActionType.CUENTA_SELECCIONA_USUARIO_STAKEHOLDER_ADD = "CUENTA_SELECCIONA_USUARIO_STAKEHOLDER_ADD";
ActionType.CUENTA_SELECCIONA_USUARIO_STAKEHOLDER_CALL = "CUENTA_SELECCIONA_USUARIO_STAKEHOLDER_CALL";
ActionType.CUENTA_LISTA_STAKEHOLDER_CALL = "CUENTA_LISTA_STAKEHOLDER_CALL";
ActionType.CUENTA_LISTA_STAKEHOLDER_ADD = "CUENTA_LISTA_STAKEHOLDER_ADD";
ActionType.CUENTA_DESCARGA_QR = "CUENTA_DESCARGA_QR";
ActionType.CUENTA_DESCARGA_QR_ZIP = "CUENTA_DESCARGA_QR_ZIP";

ActionType.PROCESO_AGREGA_CALL = "PROCESO_AGREGA_CALL";
ActionType.PROCESO_ACTUALIZA_CALL = "PROCESO_ACTUALIZA_CALL";
ActionType.PROCESO_SELECCIONA_CALL = "PROCESO_SELECCIONA_CALL";
ActionType.PROCESO_SELECCIONA_ADD = "PROCESO_SELECCIONA_ADD";
ActionType.PROCESO_LISTA_PROCESO_METADATA_ADD = "PROCESO_LISTA_PROCESO_METADATA_ADD";
ActionType.PROCESO_LISTA_PROCESO_METADATA_CALL = "PROCESO_LISTA_PROCESO_METADATA_CALL";
ActionType.PROCESO_LISTA_PROCESO_ETAPA_ADD = "PROCESO_LISTA_PROCESO_ETAPA_ADD";
ActionType.PROCESO_LISTA_PROCESO_ETAPA_CALL = "PROCESO_LISTA_PROCESO_ETAPA_CALL";
ActionType.PROCESO_LISTA_ETAPAS_PARENT_ADD = "PROCESO_LISTA_ETAPAS_PARENT_ADD";
ActionType.PROCESO_LISTA_ETAPAS_PARENT_CALL = "PROCESO_LISTA_ETAPAS_PARENT_CALL";
ActionType.PROCESO_LISTA_REGISTROS_PARENT_ADD = "PROCESO_LISTA_REGISTROS_PARENT_ADD";
ActionType.PROCESO_LISTA_REGISTROS_PARENT_CALL = "PROCESO_LISTA_REGISTROS_PARENT_CALL";
ActionType.PROCESO_REGISTROS_CONFECCION_ADD = "PROCESO_REGISTROS_CONFECCION_ADD";
ActionType.PROCESO_REGISTROS_CONFECCION_CALL = "PROCESO_REGISTROS_CONFECCION_CALL";
ActionType.PROCESO_GENERA_PRODUCTO_CODE_ADD = "PROCESO_GENERA_PRODUCTO_CODE_ADD";
ActionType.PROCESO_GENERA_PRODUCTO_CODE_CALL = "PROCESO_GENERA_PRODUCTO_CODE_CALL";
ActionType.PROCESO_LISTA_PRODUCTO_CODE_ADD = "PROCESO_LISTA_PRODUCTO_CODE_ADD";
ActionType.PROCESO_LISTA_PRODUCTO_CODE_CALL = "PROCESO_LISTA_PRODUCTO_CODE_CALL";
ActionType.PROCESO_LISTA_CODESKU_ADD = "PROCESO_LISTA_CODESKU_ADD"
ActionType.PROCESO_LISTA_CODESKU_CALL = "PROCESO_LISTA_CODESKU_CALL";
ActionType.PROCESO_DESCARGA_QR_ADD = "PROCESO_DESCARGA_QR_ADD";
ActionType.PROCESO_DESCARGA_QR_CALL = "PROCESO_DESCARGA_QR_CALL";
ActionType.PROCESO_DESCARGA_PIN_ADD = "PROCESO_DESCARGA_PIN_ADD";
ActionType.PROCESO_DESCARGA_PIN_CALL = "PROCESO_DESCARGA_PIN_CALL";
ActionType.PROCESO_ACTUALIZAR_TRAZABILIDAD_BLOCKCHAIN = "PROCESO_ACTUALIZAR_TRAZABILIDAD_BLOCKCHAIN";

ActionType.STAKEHOLDER_AGREGA_STAKEHOLDER_CALL= "STAKEHOLDER_AGREGA_STAKEHOLDER_CALL";
ActionType.STAKEHOLDER_ACTUALIZA_STAKEHOLDER_CALL = "STAKEHOLDER_ACTUALIZA_STAKEHOLDER_CALL";
ActionType.STAKEHOLDER_ELIMINA_STAKEHOLDER_CALL = "STAKEHOLDER_ELIMINA_STAKEHOLDER_CALL";
ActionType.STAKEHOLDER_LISTA_STAKEHOLDER_CALL = "STAKEHOLDER_LISTA_STAKEHOLDER_CALL";
ActionType.STAKEHOLDER_LISTA_STAKEHOLDER_ADD = "STAKEHOLDER_LISTA_STAKEHOLDER_ADD";
ActionType.STAKEHOLDER_SELECCIONA_STAKEHOLDER_CALL = "STAKEHOLDER_SELECCIONA_STAKEHOLDER_CALL";
ActionType.STAKEHOLDER_SELECCIONA_STAKEHOLDER_ADD = "STAKEHOLDER_SELECCIONA_STAKEHOLDER_ADD";

ActionType.STAKEHOLDER_AGREGA_TIPO_STAKEHOLDER_CALL= "STAKEHOLDER_AGREGA_TIPO_STAKEHOLDER_CALL";
ActionType.STAKEHOLDER_ACTUALIZA_TIPO_STAKEHOLDER_CALL = "STAKEHOLDER_ACTUALIZA_TIPO_STAKEHOLDER_CALL";
ActionType.STAKEHOLDER_ELIMINA_TIPO_STAKEHOLDER_CALL = "STAKEHOLDER_ELIMINA_TIPO_STAKEHOLDER_CALL";
ActionType.STAKEHOLDER_PROCESO_TIPO_STAKEHOLDER_ADD = "STAKEHOLDER_PROCESO_TIPO_STAKEHOLDER_ADD";
ActionType.STAKEHOLDER_LISTA_TIPO_STAKEHOLDER_CALL = "STAKEHOLDER_LISTA_TIPO_STAKEHOLDER_CALL";
ActionType.STAKEHOLDER_LISTA_TIPO_STAKEHOLDER_ADD = "STAKEHOLDER_LISTA_TIPO_STAKEHOLDER_ADD";
ActionType.STAKEHOLDER_SELECCIONA_TIPO_STAKEHOLDER_CALL = "STAKEHOLDER_SELECCIONA_TIPO_STAKEHOLDER_CALL";
ActionType.STAKEHOLDER_SELECCIONA_TIPO_STAKEHOLDER_ADD = "STAKEHOLDER_SELECCIONA_TIPO_STAKEHOLDER_ADD";

ActionType.CADENA_VALOR_LISTA_ETAPA_CALL = "CADENA_VALOR_LISTA_ETAPA_CALL";
ActionType.CADENA_VALOR_LISTA_ETAPA_ADD = "CADENA_VALOR_LISTA_ETAPA_ADD";

ActionType.CADENA_VALOR_AGREGA_CALL = "CADENA_VALOR_AGREGA_CALL";
ActionType.CADENA_VALOR_ACTUALIZA_CALL = "CADENA_VALOR_ACTUALIZA_CALL";
ActionType.CADENA_VALOR_ELIMINA_CALL = "CADENA_VALOR_ELIMINA_CALL";
ActionType.CADENA_VALOR_LISTA_CALL = "CADENA_VALOR_LISTA_CALL";
ActionType.CADENA_VALOR_LISTA_ADD = "CADENA_VALOR_LISTA_ADD";

ActionType.CADENA_VALOR_AGREGA_LOTE_CALL = "CADENA_VALOR_AGREGA_LOTE_CALL";
ActionType.CADENA_VALOR_ACTUALIZA_LOTE_CALL = "CADENA_VALOR_ACTUALIZA_LOTE_CALL";
ActionType.CADENA_VALOR_ELIMINA_LOTE_CALL = "CADENA_VALOR_ELIMINA_LOTE_CALL";
ActionType.CADENA_VALOR_SELECCIONA_LOTE_CALL = "CADENA_VALOR_SELECCIONA_LOTE_CALL";
ActionType.CADENA_VALOR_SELECCIONA_LOTE_ADD = "CADENA_VALOR_SELECCIONA_LOTE_ADD";

ActionType.REPORTE_LISTA_ETAPA_CALL = "REPORTE_LISTA_ETAPA_CALL";
ActionType.REPORTE_LISTA_ETAPA_ADD = "REPORTE_LISTA_ETAPA_ADD";
ActionType.REPORTE_LISTA_PROCESO_CALL = "REPORTE_LISTA_PROCESO_CALL";
ActionType.REPORTE_LISTA_PROCESO_ADD = "REPORTE_LISTA_PROCESO_ADD";
ActionType.REPORTE_DESCARGA_EXCEL_PROCESO_CALL = "REPORTE_DESCARGA_EXCEL_PROCESO_CALL";
ActionType.REPORTE_DESCARGA_EXCEL_PROCESO_ADD = "REPORTE_DESCARGA_EXCEL_PROCESO_ADD";
ActionType.REPORTE_CADENA_VALOR_LISTA_CALL = "REPORTE_CADENA_VALOR_LISTA_CALL";
ActionType.REPORTE_CADENA_VALOR_LISTA_ADD = "REPORTE_CADENA_VALOR_LISTA_ADD";
ActionType.REPORTE_PROCESO_METADATA_ETAPAS_CALL = "REPORTE_PROCESO_METADATA_ETAPAS_CALL";
ActionType.REPORTE_PROCESO_METADATA_ETAPAS_ADD = "REPORTE_PROCESO_METADATA_ETAPAS_ADD";
ActionType.REPORTE_DETALLE_PROCESO_CALL = "REPORTE_DETALLE_PROCESO_CALL";
ActionType.REPORTE_DETALLE_PROCESO_ADD  = "REPORTE_DETALLE_PROCESO_ADD";


ActionType.COMERCIO_AGREGA_CALL = "COMERCIO_AGREGA_CALL";
ActionType.COMERCIO_ACTUALIZA_CALL = "COMERCIO_ACTUALIZA_CALL";
ActionType.COMERCIO_ELIMINA_CALL = "COMERCIO_ELIMINA_CALL";
ActionType.COMERCIO_SELECCIONA_CALL = "COMERCIO_SELECCIONA_CALL";
ActionType.COMERCIO_SELECCIONA_ADD = "COMERCIO_SELECCIONA_ADD";
ActionType.COMERCIO_LISTA_CALL = "COMERCIO_LISTA_CALL";
ActionType.COMERCIO_LISTA_ADD = "COMERCIO_LISTA_ADD";
ActionType.COMERCIO_LISTA_CADENA_VALOR_CALL = "COMERCIO_LISTA_CADENA_VALOR_CALL";
ActionType.COMERCIO_LISTA_CADENA_VALOR_ADD = "COMERCIO_LISTA_CADENA_VALOR_ADD";
ActionType.COMERCIO_LISTA_LOTE_CALL = "COMERCIO_LISTA_LOTE_CALL";
ActionType.COMERCIO_LISTA_LOTE_ADD = "COMERCIO_LISTA_LOTE_ADD";
ActionType.COMERCIO_DESCARGA_EXCEL_CALL = "COMERCIO_DESCARGA_EXCEL_CALL";
ActionType.COMERCIO_DESCARGA_EXCEL_ADD = "COMERCIO_DESCARGA_EXCEL_ADD";

ActionType.MENU_GLOBAL_ADD = "MENU_GLOBAL_ADD";



ActionType.INTERNAL_PAGE_CALL_LIST = "INTERNAL_PAGE_CALL_LIST";
ActionType.INTERNAL_PAGE_ADD_LIST = "INTERNAL_PAGE_ADD_LIST";
ActionType.INTERNAL_PAGE_CALL_GET = "INTERNAL_PAGE_CALL_GET";
ActionType.INTERNAL_PAGE_ADD_GET = "INTERNAL_PAGE_ADD_GET";

ActionType.PAGE_ZONE_CALL_LIST = "PAGE_ZONE_CALL_LIST";
ActionType.PAGE_ZONE_ADD_LIST = "PAGE_ZONE_ADD_LIST";
ActionType.PAGE_ZONE_CALL_GET = "PAGE_ZONE_CALL_GET";
ActionType.PAGE_ZONE_ADD_GET = "PAGE_ZONE_ADD_GET";

ActionType.BANNER_CALL_LIST = "BANNER_CALL_LIST";
ActionType.BANNER_ADD_LIST = "BANNER_ADD_LIST";
ActionType.BANNER_CALL_GET= "BANNER_CALL_GET";
ActionType.BANNER_ADD_GET = "BANNER_ADD_GET";

ActionType.NEWS_CALL_CATEGORIES = "NEWS_CALL_CATEGORIES";
ActionType.NEWS_ADD_CATEGORIES = "NEWS_ADD_CATEGORIES";
ActionType.NEWS_CALL_INTERESTING = "NEWS_CALL_INTERESTING";
ActionType.NEWS_ADD_INTERESTING = "NEWS_ADD_INTERESTING";
ActionType.NEWS_CALL_LIST = "NEWS_CALL_LIST";
ActionType.NEWS_ADD_LIST = "NEWS_ADD_LIST";
ActionType.NEWS_CALL_GET = "NEWS_CALL_GET";
ActionType.NEWS_ADD_GET = "NEWS_ADD_GET";

ActionType.GALLERY_CALL_LIST = "GALLERY_CALL_LIST";
ActionType.GALLERY_ADD_LIST = "GALLERY_ADD_LIST";
ActionType.GALLERY_CALL_LIST_CONTENT = "GALLERY_CALL_LIST_CONTENT";
ActionType.GALLERY_ADD_LIST_CONTENT = "GALLERY_ADD_LIST_CONTENT";

ActionType.EVENTS_CALL_CATEGORIES = "EVENTS_CALL_CATEGORIES";
ActionType.EVENTS_ADD_CATEGORIES = "EVENTS_ADD_CATEGORIES";
ActionType.EVENTS_CALL_UPCOMING = "EVENTS_CALL_UPCOMING";
ActionType.EVENTS_ADD_UPCOMING = "EVENTS_ADD_UPCOMING";
ActionType.EVENTS_CALL_LIST = "EVENTS_CALL_LIST";
ActionType.EVENTS_ADD_LIST = "EVENTS_ADD_LIST";
ActionType.EVENTS_CALL_GET = "EVENTS_CALL_GET";
ActionType.EVENTS_ADD_GET = "EVENTS_ADD_GET";

ActionType.FORM_CALL_GET = "FORM_CALL_GET";
ActionType.FORM_ADD_GET = "FORM_ADD_GET";
ActionType.FORM_CALL_SEND = "FORM_CALL_SEND";
ActionType.FORM_ADD_SEND = "FORM_ADD_SEND";

ActionType.SEARCHER_CALL_LIST = "SEARCHER_CALL_LIST";
ActionType.SEARCHER_ADD_LIST = "SEARCHER_ADD_LIST";

export default ActionType;