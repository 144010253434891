import axios from 'axios';
import * as url from '../constants';

export const Proceso_Lista_Proceso_Metadata = ( header:object ) => {
    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.PROCESO_LISTA_PROCESO_METADATA, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Proceso_Lista_Proceso_Etapa = (body:object, header:any) => {
    header.params = body;

    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.PROCESO_LISTA_PROCESO_ETAPA, header)
        .then((res:any) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err)
        })
    })
}

export const Proceso_Agrega = (body:object, header:any) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post(url.baseURLSet.default + url.PROCESO_AGREGA, body, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Proceso_Actualiza = (body:object, header:any) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post(url.baseURLSet.default + url.PROCESO_ACTUALIZAR, body, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })  
    })
}

export const Proceso_Selecciona = (Id:number, header:any) => {
    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.PROCESO_SELECCIONA + Id, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Proceso_Lista_Etapas_Parent = (Id:number, header:any) => {
    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.PROCESO_LISTA_ETAPAS_PARENT + Id, header)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    })
}

export const Proceso_Lista_Registros_Parent = (body:object, header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post(url.baseURLSet.default + url.PROCESO_LISTA_REGISTROS_PARENT, body, header)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    })
}


export const Proceso_Registros_Confeccion = (body:object, header:any) => {
    header.params = body;
    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.PROCESO_REGISTROS_CONFECCION, header)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    })
}

export const Proceso_Genera_Producto_Code = (body:string, header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post(url.baseURLSet.default + url.PROCESO_GENERA_PRODUCTO_CODE + body, {}, header)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    })
}

export const Proceso_Lista_Producto_Code = (Id:number, header:object) => {
    return new Promise((resolve:any, reject:any) => 
        axios.get(url.baseURLSet.default + url.PROCESO_LISTA_PRODUCTO_CODE + Id, header)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    )
}

export const Proceso_Lista_Codesku = (header:object) => {
    return new Promise((resolve:any, reject:any) => 
        axios.get(url.baseURLSet.default + url.PROCESO_LISTA_CODESKU, header)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    )
}

export const Proceso_Descarga_Qr = (Id:number, header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.PROCESO_DESCARGA_QR + Id, header)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    })
}

export const Proceso_Descarga_Pin = (Id:number, header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post(url.baseURLSet.default + url.PROCESO_DESCARGA_PIN + Id, {}, header)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    })
}

export const Proceso_Actualizar_Trazabilidad_BlockChain = (header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post(url.baseURLSet.default + url.PROCESO_ACTUALIZAR_TRAZABILIDAD_BLOCKCHAIN, {}, header)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
}